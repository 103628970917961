<template>
  <div>
    <div></div>
    <div>
      <div class="d-flex justify-space-between">
        <div class="font-24 mt-10 ml-4">Edit Order</div>
        <div>
          <v-btn class="mr-4 mt-10 ml-4" @click="purchaseOrder(id)" :disabled="loader"
            >Export PO</v-btn
          >
        </div>
      </div>
      <div class="justify-center" align="center">
        <!-- Top Bar Chart -->
        <v-card class="mt-2 mx-4">
          <div class="panel-content">
            <div class="row">
              <div class="col-md-12">
                <div class="widget">
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form
                        name="details"
                        class="formPart text-left"
                        @submit.prevent="orderStatusCheck(id)"
                      >
                        <div>
                          <div class="form-group col">
                            <label for="Order No">Order No:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="orderNo"
                              placeholder="Enter Order No"
                              v-model.trim="details.number"
                              readonly
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="Voucher No">Voucher No:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="VoucherNo"
                              placeholder="Enter Voucher No"
                              v-model.trim="details.advanceVoucherNumber"
                              readonly
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="customerId">Tracking Id:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="number"
                              placeholder="Tracking Id"
                              v-model.trim="details.trackingCode"
                            />
                            <!-- <div
                              class="error"
                              v-if="
                                !$v.details.trackingCode.required && $v.details.trackingCode.$error
                              "
                            >
                              Tracking Id is required
                            </div> -->
                          </div>
                        </div>

                        <div>
                          <div class="form-group col">
                            <label for="Quantity">Quantity:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="quantity"
                              placeholder="Enter Quantity"
                              v-model.trim="details.quantity"
                              readonly
                            />
                          </div>
                        </div>

                        <div>
                          <div class="form-group col">
                            <label for="Price">Price:</label>
                            <!-- <input
                              type="number"
                              class="form-control"
                              id="price"
                              placeholder="Enter Price"
                              v-model="details.price"
                              @input="setTouch('price')"
                              pattern="[0-9]*[.]?[0-9]+"
                              readonly
                            /> -->
                            <div class="form-group-col border py-2 order-date">
                              <div
                                v-if="
                                  details.defaultDiscount &&
                                  details.defaultDiscount > 0 &&
                                  details.defaultDiscountPrice &&
                                  details.defaultDiscountPrice > 0
                                "
                              >
                                {{ details.defaultDiscountPrice | currency }}
                              </div>
                              <div v-else>
                                {{ details.price | currency }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div class="form-group col">
                            <label for="date"> Order Date:</label>
                            <!-- <input
                              readonly
                              type="text"
                              class="form-control"
                              id="date"
                              placeholder="Enter Date"
                              v-model.trim="details.date"
                              @input="setTouch('date')"
                            /> -->
                            <div class="form-group-col border py-2 order-date">
                              {{ details.date | moment }}
                            </div>
                          </div>
                        </div>

                        <div v-if="orderCustomisation && orderCustomisation.length > 0">
                          <div class="form-group col">
                            <label> Customisation Added:</label>
                            <!-- <input
                              readonly
                              type="date"
                              class="form-control"
                              id="date"
                              placeholder="Enter Date"
                              v-model.trim="details.date"
                              @input="setTouch('date')"
                            /> -->
                            <div class="form-group-col py-2">
                              <div class="table-area mt-2">
                                <div class="table-responsive">
                                  <table class="table">
                                    <tbody class="border">
                                      <tr
                                        v-for="(category, index) in orderCustomisation"
                                        v-bind:key="index"
                                        class="border"
                                      >
                                        <td class="border">
                                          <div class="font-16 poppins-700 maroon-txt">
                                            {{ category.name }}
                                          </div>
                                        </td>
                                        <td
                                          v-for="(layer, index) in category.layers"
                                          v-bind:key="index"
                                        >
                                          <div class="font-16 poppins-medium grey--text">
                                            {{ layer.name }}
                                          </div>
                                          <div class="font-14 font-weight-bold">
                                            {{ layer.toggles[layer.selectedToggle].name }}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="form-group col" v-if="details.customizationImage">
                              <label for="delivery date">Customisation Image:</label>
                              <div class="form-group-col border py-2 order-date">
                                <v-img
                                  :src="details.customizationImage"
                                  width="300px"
                                  height="400px"
                                ></v-img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div
                            class="form-group col"
                            :class="{
                              'form-group--error': $v.details.orderStatus.$error,
                            }"
                          >
                            <label for="orderStatus">Order Status:</label>
                            <v-select
                              v-model="details.orderStatus"
                              :items="status"
                              label="Select Order Status"
                              solo
                              color="grey"
                            ></v-select>
                            <div
                              class="error"
                              v-if="
                                !$v.details.orderStatus.required && $v.details.orderStatus.$error
                              "
                            >
                              order status is required
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            class="form-group col"
                            :class="{
                              'form-group--error': $v.details.orderStatus.$error,
                            }"
                          >
                            <label for="mobile">Internal Order Status:</label>
                            <v-select
                              v-model="details.internalOrderStatus"
                              :items="internalStatus"
                              label="Select Status"
                              solo
                              color="grey"
                            ></v-select>
                            <div
                              class="error"
                              v-if="
                                !$v.details.internalOrderStatus.required &&
                                $v.details.internalOrderStatus.$error
                              "
                            >
                              internal Order Status is required
                            </div>
                          </div>
                        </div>

                        <div>
                          <div class="form-group col">
                            <label for="comments" class="mb-2"
                              >Palettier Comments:
                              <span
                                ><v-btn @click="view = true" dense width="80px"
                                  >View All</v-btn
                                ></span
                              ></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="comments"
                              placeholder="Enter Comments"
                              v-model.trim="comment.text"
                            />
                            <!-- <div
                              class="error"
                              v-if="!$v.details.comment.required && $v.details.comment.$error"
                            >
                              Comments is required
                            </div>  -->
                          </div>
                        </div>
                        <div>
                          <div
                            class="form-group col"
                            :class="{
                              'form-group--error': $v.details.customerId.$error,
                            }"
                          >
                            <label for="customerId">Customer Id:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="number"
                              placeholder="Customer Id"
                              v-model.trim="details.customerId"
                              @input="setTouch('customerId')"
                            />
                            <div
                              class="error"
                              v-if="!$v.details.customerId.required && $v.details.customerId.$error"
                            >
                              Customer Id is required
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="name">Customer Name:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="customerName"
                              placeholder="Customer Name"
                              v-model.trim="details.customerName"
                              @input="setTouch('customerName')"
                              readonly
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="name">Address:</label>
                            <textarea
                              type="name"
                              class="form-control"
                              id="name"
                              placeholder="Address"
                              v-model.trim="details.shippingAddress"
                              @input="setTouch('address')"
                              readonly
                              rows="6"
                            ></textarea>
                            <!-- <div
                              class="error"
                              v-if="
                                !$v.details.shippingAddress.required &&
                                $v.details.shippingAddress.$error
                              "
                            >
                              Address is required
                            </div> -->
                          </div>
                        </div>
                        <div v-if="isGift === true">
                          <div class="form-group col">
                            <label for="giftDetails">Gift Details:</label>
                            <div class="mt-2">
                              <label for="from">From:</label>
                              <input
                                type="text"
                                class="form-control"
                                id="name"
                                label="From"
                                v-model.trim="details.giftDetails.from"
                                @input="setTouch('from')"
                                readonly
                              />
                              <!-- <div
                                class="error"
                                v-if="
                                  !$v.details.giftDetails.from.required &&
                                  $v.details.giftDetails.from.$error
                                "
                              >
                                From is required
                              </div> -->
                              <label for="note">Note:</label>
                              <input
                                type="text"
                                class="form-control"
                                id="name"
                                label="Note"
                                v-model.trim="details.giftDetails.note"
                                @input="setTouch('note')"
                                readonly
                              />
                              <!-- <div
                                class="error"
                                v-if="
                                  !$v.details.giftDetails.note.required &&
                                  $v.details.giftDetails.note.$error
                                "
                              >
                                Note is required
                              </div> -->
                              <label for="To">To:</label>
                              <input
                                type="text"
                                class="form-control"
                                id="name"
                                label="To"
                                v-model.trim="details.giftDetails.to"
                                @input="setTouch('to')"
                                readonly
                              />
                              <!-- <div
                                class="error"
                                v-if="
                                  !$v.details.giftDetails.to.required &&
                                  $v.details.giftDetails.to.$error
                                "
                              >
                                To is required
                              </div> -->
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="email">Customer Email:</label>
                            <input
                              type="email"
                              class="form-control"
                              id="email"
                              placeholder="Customer Email"
                              v-model.trim="details.email"
                              readonly
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="number">Customer Number:</label>
                            <input
                              type="number"
                              class="form-control"
                              id="number"
                              placeholder="Customer Number"
                              v-model.trim="details.customerNumber"
                              readonly
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="text">Brand Name:</label>
                            <input
                              type="name"
                              class="form-control"
                              id="brandName"
                              placeholder="Brand Name"
                              v-model.trim="details.brandName"
                              readonly
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="text">Product Name:</label>
                            <input
                              type="name"
                              class="form-control"
                              id="productName"
                              placeholder="Product Name"
                              v-model.trim="details.productName"
                              readonly
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="text">SKU:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="sku"
                              placeholder="SKU"
                              v-model.trim="details.sku"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="form-group col">
                          <label for="color">Color:</label>
                          <v-row no-gutters>
                            <v-col cols="5" class="mr-6">
                              <input
                                type="text"
                                class="form-control"
                                id="color"
                                placeholder="color"
                                v-model.trim="details.color"
                                readonly
                              />
                            </v-col>
                            <v-col cols="5">
                              <div class="py-1" v-if="details.icon">
                                <v-img :src="details.icon" class="icon-img"></v-img>
                              </div>
                              <div class="py-1" v-else>
                                <div
                                  class="rounded-color pa-3"
                                  :style="'background-color: ' + details.color"
                                ></div>
                              </div>
                            </v-col>
                          </v-row>
                        </div>

                        <!-- <table>
                          <tr>
                            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">
                              <div class="font-22 poppins-700 d-flex">Color:</div>
                            </th>
                            <td
                              class="poppins-medium font-16 pl-4 d-block"
                              v-for="(color, index) in details.Color"
                              :key="index"
                            >
                              <v-row no-gutters
                                ><v-col cols="4" class="d-flex px-2 ma-1 pa-1">
                                  <v-text-field
                                    v-model="color.color"
                                    class="mr-4"
                                    v-if="color.color"
                                    readonly
                                    placeholder="hex code"
                                    >{{ userConfig.color }}
                                  </v-text-field>
                                  <v-text-field
                                    v-model="color.name"
                                    class="mr-4"
                                    v-if="color.name"
                                    readonly
                                    placeholder="hex code"
                                    >{{ color.name }}
                                  </v-text-field>
                                  <div
                                    class="rounded-color pa-3"
                                    :style="'background-color: ' + color.color"
                                  ></div> </v-col
                              ></v-row>
                            </td>
                          </tr>
                        </table> -->
                        <div>
                          <div class="form-group col">
                            <label for="email">SIZE:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="number"
                              placeholder="SKU"
                              v-model.trim="details.size"
                              readonly
                            />
                          </div>
                        </div>
                        <div v-if="sizeProfile">
                          <div class="form-group col">
                            <label for="size profile">Size Profile:</label>

                            <div class="measurement-table">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Measurement Type</th>
                                    <th>Measurement Value</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(value, key) in filteredData" :key="key">
                                    <td>{{ key }}</td>
                                    <td v-if="isObject(value)">
                                      <table class="nested-table">
                                        <tr
                                          v-for="(nestedValue, nestedKey) in value"
                                          :key="nestedKey"
                                        >
                                          <td :style="{ width: '50%' }">
                                            {{ nestedKey }}
                                          </td>
                                          <td :style="{ width: '50%' }">
                                            {{ nestedValue }}
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                    <td v-else>{{ value }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="delivery date">Delivery Date:</label>
                            <div class="form-group-col border py-2 order-date">
                              {{ details.deliveryDate | moment }}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="delivery date">Product Image:</label>
                            <div class="form-group-col border py-2 order-date">
                              <v-img
                                :src="details.productImage"
                                width="300px"
                                height="400px"
                              ></v-img>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="Place Of Supply">Place Of Supply:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="placeOfSupply"
                              placeholder="Place Of Supply"
                              v-model.trim="details.placeOfSupply"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="Tax Rate">TAX RATE:</label>
                            <input
                              type="number"
                              class="form-control"
                              id="number"
                              placeholder="Tax Percentage"
                              v-model.trim="details.taxPercentage"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="Tax Rate">HSN CODE:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="hsn code"
                              placeholder="HSN CODE"
                              v-model.trim="details.hsnCode"
                            />
                          </div>
                        </div>

                        <div>
                          <div class="form-group col">
                            <label for="Cgst Percentage">CGST %:</label>
                            <input
                              type="number"
                              class="form-control"
                              id="number"
                              placeholder=" CGST Percentage"
                              v-model.trim="details.cgstPercentage"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="Cgst Percentage">SGST %:</label>
                            <input
                              type="number"
                              class="form-control"
                              id="number"
                              placeholder=" SGST Percentage"
                              v-model.trim="details.sgstPercentage"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="Cgst Percentage">IGST %:</label>
                            <input
                              type="number"
                              class="form-control"
                              id="number"
                              placeholder=" IGST Percentage"
                              v-model.trim="details.igstPercentage"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="Cgst Percentage">DISCOUNT %:</label>
                            <input
                              type="number"
                              class="form-control"
                              id="number"
                              placeholder=" DISCOUNT Percentage"
                              v-model.trim="details.discount"
                            />
                          </div>
                        </div>

                        <!-- button -->
                        <div class="btnPart text-center my-4">
                          <button type="submit" class="btn btn-success" :disabled="loader">
                            Save
                          </button>
                          <p class="typo__p" v-if="submitStatus === 'OK'">
                            Thanks for your submission!
                          </p>
                          <p class="typo__p" v-if="submitStatus === 'ERROR'">
                            Please fill the form correctly.
                          </p>
                          <button
                            type="reset"
                            class="btn btn-danger mx-3"
                            :disabled="loader"
                            v-on:click="resetForm()"
                          >
                            Cancel
                          </button>
                        </div>
                        <div class="mt-2 mb-2" v-if="loader" align="center">
                          <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
        <v-row justify="center">
          <v-dialog v-model="view" scrollable max-width="600px">
            <v-card class="px-4 justify-center text-center" align="center">
              <v-row no-gutters class="text-center" align="center">
                <v-col cols="2"></v-col>
                <v-col cols="8"
                  ><div class="h4 text-center mt-2 pt-2">Palettier Comments</div></v-col
                >
                <v-col cols="2"
                  ><div class="float-right">
                    <img src="@/assets/Images/close.svg" @click="view = false" /></div
                ></v-col>
              </v-row>

              <v-divider></v-divider>

              <div class="table-area mt-5">
                <div
                  class="table-responsive"
                  v-if="details.comments && details.comments.length > 0"
                >
                  <table class="table">
                    <tbody class="border">
                      <tr v-for="(comment, index) in details.comments" :key="index">
                        <td v-if="comment.text">
                          <div v-if="comment.text">{{ comment.text }}</div>
                        </td>
                        <td v-if="comment.text">
                          <div class="float-right">
                            <img
                              src="@/assets/Images/close.svg"
                              @click="deleteComment(index)"
                              class="delete-icon"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>No Comments</div>
              </div>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <ConfirmDlg ref="confirm" />
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import orderService from '../../services/orderService';
import customerService from '../../services/customerService';
import ConfirmDlg from '../../components/ConfirmDlg.vue';
import moment from 'moment';

export default {
  components: { ConfirmDlg },
  data() {
    return {
      details: {
        number: '',
        quantity: '',
        orderStatus: '',
        internalOrderStatus: '',
        price: '',
        customerId: '',
        giftDetails: {
          from: '',
          note: '',
          to: '',
        },
        shippingAddress: '',
        customerName: '',
        customerNumber: '',
        // date: '',
        email: '',
        comments: [],
        brandName: '',
        productName: '',
        sku: '',
        icon: '',
        color: '',
        size: '',
        deliveryDate: '',
        primaryColor: '',
        productImage: '',
        placeOfSupply: '',
        taxPercentage: '',
        hsnCode: '',
        cgstPercentage: 0,
        sgstPercentage: 0,
        igstPercentage: 0,
        discount: 0,
        advanceVoucherNumber: '',
        customizationImage: '',
        trackingCode: '',
      },
      comment: { text: '' },
      date: '',
      submitStatus: false,
      id: '',
      status: ['pending', 'cancelled', 'completed', 'processing', 'shipped', 'pendingQuery'],
      orderAddress: {},
      isGift: false,
      internalStatus: [
        'Pending Approval',
        'In Production',
        'ReceivedatWarehouse',
        'QC Pass',
        'Package Ready',
        'Dispatched to Customer',
        'DeliveredtoCustomer',
        'QC Fail',
        'Dispatched to Designer',
        'Late',
        'Cancelled',
      ],
      orderCustomisation: [],
      show: false,
      internalCustomerId: '',
      view: false,
      sizeProfile: {},
      loader: false,
      defaultDiscountPrice: 0,
      defaultDiscount: 0,
    };
  },
  validations: {
    details: {
      number: {
        required,
      },
      quantity: {
        required,
      },
      orderStatus: {
        required,
      },
      internalOrderStatus: {
        required,
      },
      price: {
        required,
      },
      customerId: {
        required,
      },
      // comments: {
      //   required,
      // },
      name: {
        required,
      },

      date: {
        required,
      },
      shippingAddress: {
        required,
      },
      // trackingCode: {
      //   required,
      // },
    },
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.getOneOrder(this.id);
    }
  },
  methods: {
    setTouch(fields) {
      if (fields === 'number') {
        this.$v.details.number.$touch();
      } else if (fields === 'quantity') {
        this.$v.details.quantity.$touch();
      } else if (fields === 'orderStatus') {
        this.$v.details.orderStatus.$touch();
      } else if (fields === 'internalOrderStatus') {
        this.$v.details.internalOrderStatus.$touch();
      } else if (fields === 'price') {
        this.$v.details.price.$touch();
      } else if (fields === 'customerId') {
        this.$v.details.customerId.$touch();

        // else if (fields === 'comments') {
        //   this.$v.details.comments.$touch();
      } else if (fields === 'name') {
        this.$v.details.name.$touch();
      } else if (fields === 'date') {
        this.$v.details.date.$touch();
      } else if (fields === 'address') {
        this.$v.details.shippingAddress.$touch();
      } else if (fields === 'trackingCode') {
        this.$v.details.trackingCode.$touch();
      }
      //  else if (fields === 'trackingCode') {
      //   this.$v.details.trackingCode.$touch();
      // }
    },
    async orderStatusCheck(id) {
      console.log('iam called', id, this.details.trackingCode);
      if (
        this.details.orderStatus === 'shipped' &&
        this.details.internalOrderStatus === 'Dispatched to Customer'
      ) {
        if (this.details.trackingCode === '' || this.details.trackingCode === null) {
          this.$store.commit('setSnackbar', {
            content: 'Tracking Code is required',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        } else {
          if (await this.$refs.confirm.open('Confirm', 'Do you want to send delivery mail ?')) {
            this.loader = true;
            if (this.loader) {
              this.$store.commit('setSnackbar', {
                content: 'Please wait while we sending invoice to your mail',
                icon: 'mdi-alert-circle',
                color: 'error ',
                isVisible: true,
                timeout: '8000',
              });
            }
            const result = await orderService.sendShippedMail({
              id: id,
              trackingCode: this.details.trackingCode,
            });
            this.loader = false;
            if (
              result &&
              result.status === 200 &&
              result.data &&
              result.data.data &&
              result.data.data === 'Email sent successfully'
            ) {
              this.$store.commit('setSnackbar', {
                content: 'Email sent successfully',
                icon: 'mdi-check-circle',
                color: 'success ',
                isVisible: true,
              });
              this.submitForm(id);
            } else {
              this.$store.commit('setSnackbar', {
                content: 'something went wrong',
                icon: 'mdi-check-circle',
                color: 'error ',
                isVisible: true,
              });
            }
          }
        }
      } else {
        this.submitForm(id);
      }
    },
    downloadPurchaseOrder(response, title) {
      const url = response;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
    async purchaseOrder(id) {
      this.loader = true;
      if (this.loader) {
        this.$store.commit('setSnackbar', {
          content: 'Please wait till your Purchase Order downloads',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
          timeout: '8000',
        });
      }
      const result = await orderService.generatePurchaseOrder({
        id: id,
      });
      this.loader = false;
      console.log('purchase::::', result.data);
      this.downloadPurchaseOrder(result.data, 'purchaseOrder.xlsx');
    },
    async submitForm(id) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        //     this.submitStatus = 'false';
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      } else {
        console.log(id, 'id');
        this.submitStatus = 'true';
        // const commentObj = {}
        // commentObj.name = this.details.comment
        console.log(this.details.comments, 'comments');
        console.log(this.comment, 'comment');
        this.details.comments.push(this.comment);

        delete this.details.shippingAddress;
        const data = {};
        data.customerId = this.details.customerId;

        const result = await orderService.updateOrderData(id, this.details);
        const updateId = await customerService.updateUserData(this.internalCustomerId, data);
        if (result.status === 200 && updateId.status === 200) {
          this.$store.commit('setSnackbar', {
            content: 'Data updated successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          this.$router.push('/orders');
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      }
    },
    async getOneOrder(id) {
      console.log(id, 'id');
      const result = await orderService.getOneOrder(id);
      const data = result.data[0];
      this.details.number = data.number;
      this.details.customerId = data.customerData[0].customerId;
      this.details.email = data.customerData[0].email;
      this.details.customerNumber = data.customerData[0].mobile;
      this.details.brandName = data.vendorData[0].brandName;
      this.details.productName = data.productConfig.product.name;
      this.details.customerName = data.customerData[0].name;
      this.details.placeOfSupply = data.placeOfSupply;
      this.details.sku = data.productConfig.product.sku;
      this.details.icon = data.productConfig.userConfig.icon;
      this.details.color = data.productConfig.userConfig.color;
      this.details.size = data.productConfig.userConfig.size;
      this.details.deliveryDate = data.timelineDate;
      this.details.productImage =
        data.productConfig.product.images &&
        data.productConfig.product.images.length &&
        data.productConfig.product.images[0] &&
        data.productConfig.product.images[0].imageUrl;
      this.details.cgstPercentage = data.cgstPercentage;
      this.details.sgstPercentage = data.sgstPercentage;
      this.details.igstPercentage = data.igstPercentage;
      this.details.taxPercentage = data.taxPercentage;
      this.details.discount = data.discount;
      this.details.trackingCode = data.trackingCode;
      this.details.hsnCode = data.hsnCode;
      this.details.advanceVoucherNumber = data.transactionData[0].advanceVoucherNumber;
      this.sizeProfile = data.sizeProfileObject;

      if (data && data.comments && data.comments.length > 0) {
        //  this.comment.text = this.details.comments[this.details.comments.length - 1].text;
        this.details.comments = data.comments;
      }

      this.details.orderStatus = data.orderStatus;
      this.details.internalOrderStatus = data.internalOrderStatus;
      this.orderAddress = data.shippingAddressObject;
      this.details.shippingAddress =
        this.orderAddress.address +
        ',' +
        '\n' +
        this.orderAddress.area +
        ',' +
        '\n' +
        this.orderAddress.city +
        ',' +
        '\n' +
        this.orderAddress.state +
        ',' +
        '\n' +
        this.orderAddress.mobile;
      this.details.price = data.price;
      this.details.defaultDiscount = data.defaultDiscount;
      this.details.defaultDiscountPrice = data.defaultDiscountPrice;
      this.details.date = data.createdAt;
      if (
        data.productConfig &&
        data.productConfig.customization &&
        data.productConfig.customization.categories &&
        data.productConfig.customization.categories.length > 1
      ) {
        this.orderCustomisation = data.productConfig.customization.categories;
        this.details.customizationImage = data.productConfig.customizationImage;
      }

      this.show = data.productConfig.product.isCustomized;
      if (data.customerData && data.customerData.length && data.customerData[0]) {
        this.internalCustomerId = data.customerData[0]._id;
        this.details.name = data.customerData[0].name;
      }

      this.details.quantity = data.quantity;
      this.isGift = data.isThisGift;
      if (this.isGift === true) {
        this.details.giftDetails.from = result.data.giftDetails.from;
        this.details.giftDetails.note = result.data.giftDetails.note;
        this.details.giftDetails.to = result.data.giftDetails.to;
      }
    },

    async deleteComment(index) {
      await this.details.comments.splice(index, 1);
    },

    resetForm() {
      this.details = 'blank';
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async deleteConfirm() {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.deleteUserById(id);
        console.log('got confirmation');
      }
    },
    isObject(value) {
      return typeof value === 'object' && value !== null && !Array.isArray(value);
    },
  },
  computed: {
    filteredData() {
      // remove unwanted fields
      const { user, createdAt, updatedAt, __v, status, _id, ...filteredData } = this.sizeProfile;
      return filteredData;
    },
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY');
    },
  },

  // this method use for cancel button
};
</script>
<style lang="scss" scoped>
.preview-image {
  width: 40%;
  height: 350px;
}
.icon-img {
  width: 30px !important;
}
.rounded-color {
  width: 35px;
  height: 35px;
  border-radius: 100%;
}
.order-date {
  background-color: #e9ecef !important;
}
.delete-icon {
  width: 20px !important;
  height: 20px !important;
}
.measurement-table {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}
.nested-table {
  border-collapse: collapse;
  margin: 0;
  width: 100%;
}

.nested-table th,
.nested-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.nested-table th {
  background-color: #f2f2f2;
}
</style>
